import { render, staticRenderFns } from "./TransferOwnerShip.vue?vue&type=template&id=b50434d0&scoped=true"
import script from "./TransferOwnerShip.vue?vue&type=script&lang=js"
export * from "./TransferOwnerShip.vue?vue&type=script&lang=js"
import style0 from "./TransferOwnerShip.vue?vue&type=style&index=0&id=b50434d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b50434d0",
  null
  
)

export default component.exports